<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Promotional item types">
                <delete-items-button v-if="$hasPermission($permissions.RemovePromotionalItems)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>
                <b-button v-if="$hasPermission($permissions.AddPromotionalItems)" variant="primary" @click="addPromotionalItemTypeModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>


                <basic-table ref="basicTable" :columns="columns" :data="promotionalItemTypes" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 2 && $hasPermission($permissions.RemovePromotionalItems)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditPromotionalItems)" variant="warning" @click="openPromotionalItemTypeEdit(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemovePromotionalItems)" variant="danger" @click="removePromotionalItemType(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>

            </b-card>
        </b-overlay>

        <b-modal title="Add promotional item type" v-model="addPromotionalItemTypeModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addPromotionalItemTypeModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addPromotionalItemType">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit promotional item type" v-model="editPromotionalItemTypeModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="editObject.name"/>
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editPromotionalItemTypeModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="savePromotionalItemType">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                promotionalItemTypes: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 2,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addPromotionalItemTypeModalActive: false,
                addObject: {
                    name: ''
                },

                editPromotionalItemTypeModalActive: false,
                editObject: {
                    name: ''
                }
            }
        },
        methods: {
            openPromotionalItemTypeEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editPromotionalItemTypeModalActive = true
            },
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/promotional_item_type')
                loadPromise.then(function(response) {
                    thisIns.promotionalItemTypes = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addPromotionalItemType() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/promotional_item_type', this.addObject)
                addPromise.then(function() {

                    thisIns.$printSuccess('Promotional item type added')

                    thisIns.addPromotionalItemTypeModalActive = false
                    thisIns.addObject = {
                        name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            savePromotionalItemType() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/promotional_item_type/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {

                    thisIns.$printSuccess('Promotional item type saved')

                    thisIns.editPromotionalItemTypeModalActive = false
                    thisIns.editObject = {
                        name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removePromotionalItemType(id) {

                try {

                    const confirmDeletion = await this.$confirmDialog('Do you want to remove this promotional item type?')
                    
                    if (!confirmDeletion) return
                    await this.$http.delete(`/api/management/v1/promotional_item_type/${  id}`)
                    this.$printSuccess('Promotional item type removed')

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        mounted() {
            this.selection.collection_name = 'promotional_item_types'
            this.loadData()
        }
    }
</script>